
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import Selector from '@hems/component/src/selectors/Selector/Selector.vue';

import type { BatteryInfo } from 'hems/device/dashboard';

export default defineComponent({
  name: 'BatteryPackItem',
  components: {
    Selector,
  },
  props: {
    num: Number,
    data: {
      type: Object as PropType<BatteryInfo>,
    },
  },
  setup(props) {
    const state = reactive({
      selected: props.data?.serial_num || null,
    });

    const { t } = useI18n();
    const showDetails = computed(() => !!state.selected);
    const options = computed(() =>
      props.data?.serial_num
        ? [
            { text: t('common.select'), value: null },
            { text: props.data.serial_num, value: props.data.serial_num },
          ]
        : [{ text: t('common.empty'), value: null }]
    );

    const batModules = computed(() => (props.data?.module ? JSON.parse(props.data.module) : []));

    return {
      state,
      showDetails,
      options,
      batModules,
    };
  },
});
