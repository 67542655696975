import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b5741a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "button-icon",
    disabled: _ctx.isDisabled
  }, [
    _createVNode(_component_Image2, {
      src: _ctx.iconSrc,
      class: "icon-image",
      extension: "svg"
    }, null, 8, ["src"])
  ], 8, _hoisted_1))
}