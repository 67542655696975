import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3211a552"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "fm_wrap" };
const _hoisted_2 = { class: "label-tooltip-wrap" };
const _hoisted_3 = ["for"];
const _hoisted_4 = { class: "tooltip_wrap" };
const _hoisted_5 = {
    key: 0,
    class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_BasicInput = _resolveComponent("BasicInput");
    return (_openBlock(), _createElementBlock("label", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.label)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "label",
                    for: _ctx.name
                }, _toDisplayString(_ctx.label), 9, _hoisted_3))
                : _createCommentVNode("", true)
        ]),
        _createVNode(_component_BasicInput, {
            modelValue: _ctx.state.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.value) = $event)),
            name: _ctx.name,
            placeholder: _ctx.placeholder,
            readonly: _ctx.state.readonly,
            disabled: _ctx.disabled
        }, {
            default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["tooltip_text", _ctx.tooltipPosition?.join(' ')])
                    }, [
                        (_ctx.tooltipText)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.tooltipText), 1))
                            : _createCommentVNode("", true)
                    ], 2)
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "name", "placeholder", "readonly", "disabled"])
    ]));
}
