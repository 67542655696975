import { Helper } from '@hems/util';
import { LANGUAGE, localStorageKey } from '@hems/util/src/constant';
import { getLanguageValue } from '@hems/util/src/helper/tsguardHelper';
const localStorageLanguage = localStorage.getItem(localStorageKey.language) ?? LANGUAGE.EN;
const mobileFlatform = Helper.getMobileOperatingSystem();
// cookie로 부터 선택된 locale 정보를 가져오고 없으면
// DB에 저장된 Locale 정보 가져오고
// 그것도 없으면 영어로 default 선택
export const initState = {
    langCd: getLanguageValue(localStorageLanguage),
    envLocale: Helper.getEnvLocale(),
    termsVer: process.env[`VUE_APP_TERMS_VER_${Helper.getEnvLocale()}`] ?? '',
    curNav: [],
    scroll: null,
    bgImageNum: 1,
    mode: mobileFlatform !== 'unknown' ? 'mobile' : 'pc',
    theme: 'light',
};
export default {
    namespaced: true,
    state: initState,
    mutations: {
        setLangCd(state, payload) {
            localStorage.setItem(localStorageKey.language, payload);
            state.langCd = payload;
        },
        setScroll(state, payload) {
            state.scroll = payload;
        },
        setBgImageNum(state, payload) {
            state.bgImageNum = payload;
        },
        setMode(state, payload) {
            state.mode = payload;
        },
        setTheme(state, payload) {
            state.theme = payload;
        },
    },
    actions: {
        setLangCd({ commit }, payload) {
            commit('setLangCd', payload);
        },
        setScroll({ commit }, payload) {
            commit('setScroll', payload);
        },
        setBgImageNum({ commit }, payload) {
            commit('setBgImageNum', payload);
        },
        setMode({ commit }, payload) {
            commit('setMode', payload);
        },
        setTheme({ commit }, payload) {
            commit('setTheme', payload);
        },
    },
};
