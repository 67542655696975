import { defineComponent } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import Image2 from '@hems/component/src/image/Image2.vue';
import { CONTENT_MODAL_SIZE_TYPE } from '@hems/util/src/constant';
import { SCHEDULER_INTERACTIVE_STATUS } from '@hems/util/src/constant/energyManagement';
export default defineComponent({
    name: 'ContentModal',
    components: { VueFinalModal, Image2 },
    props: {
        size: {
            type: String,
            default: CONTENT_MODAL_SIZE_TYPE.MEDIUM,
        },
        useCloseButton: {
            type: Boolean,
            default: true,
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },
        teleportTo: {
            type: String,
            default: 'body',
        },
        backgroundInteractive: {
            type: String,
            default: SCHEDULER_INTERACTIVE_STATUS.NON_INTERACTIVE,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const modalSizeClassNameMap = {
            [CONTENT_MODAL_SIZE_TYPE.SMALL]: 'content-modal-small',
            [CONTENT_MODAL_SIZE_TYPE.MEDIUM]: 'content-modal-medium',
            [CONTENT_MODAL_SIZE_TYPE.LARGE]: 'content-modal-large',
            [CONTENT_MODAL_SIZE_TYPE.UPDATE_INFO]: 'content-modal-update-info',
        };
        const modalSizeClass = modalSizeClassNameMap[props.size];
        const onClose = (close) => {
            emit('close');
            close();
        };
        return { modalSizeClass, onClose };
    },
});
