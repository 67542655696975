import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e02a127c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "label-tooltip-wrap" };
const _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Tooltip = _resolveComponent("Tooltip");
    const _component_BasicInput = _resolveComponent("BasicInput");
    return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass(["fm_wrap", _ctx.className])
    }, [
        _createElementVNode("div", _hoisted_1, [
            (_ctx.label)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["label", { required: _ctx.required }]),
                    for: _ctx.name
                }, _toDisplayString(_ctx.label), 11, _hoisted_2))
                : _createCommentVNode("", true),
            (_ctx.tooltipText)
                ? (_openBlock(), _createBlock(_component_Tooltip, {
                    key: 1,
                    "tooltip-position": ['top'],
                    "tooltip-text": _ctx.tooltipText,
                    "image-base-url": "svg",
                    "image-name": "warning_circle",
                    "image-extension": "svg",
                    width: _ctx.tooltipWidth
                }, null, 8, ["tooltip-text", "width"]))
                : _createCommentVNode("", true)
        ]),
        _createVNode(_component_BasicInput, _mergeProps({
            modelValue: _ctx.state.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.value) = $event)),
            name: _ctx.name
        }, _ctx.$attrs, { placeholder: _ctx.placeholder }), null, 16, ["modelValue", "name", "placeholder"])
    ], 2));
}
