
import { defineComponent, reactive } from 'vue';

import { useModelWrapper } from '@hems/util';

import BasicInput from './BasicInput.vue';
import Tooltip from '../tooltip/Tooltip.vue';
export default defineComponent({
  name: 'LabelInput',
  components: {
    BasicInput,
    Tooltip,
  },
  inheritAttrs: false,
  props: {
    label: String,
    name: String,
    placeholder: String,
    className: [String, Object],
    modelValue: [String, Number],
    tooltipText: String,
    tooltipWidth: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      value: useModelWrapper(props, emit),
    });

    return {
      state,
    };
  },
});
