/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';
import type {
  ACCombinerKey,
  BatteryKey,
  EnergyHourKey,
  EnergyMinuteKey,
  GridKey,
  PowerKey,
} from 'hems/device/statistics/common';

export const GRAPH_TYPE = {
  CATEGORY: 'CATEGORY',
  INTEGRATED: 'INTEGRATED',
} as const;

export type GraphTypeValue = ValueType<typeof GRAPH_TYPE>;

export const GRAPH_TERM_UNIT = {
  MINUTE: 'TH',
  HOUR: 'TD',
  MONTH: 'TM',
} as const;

export type GraphTermUnitValue = Omit<ValueType<typeof GRAPH_TERM_UNIT>, 'TM'>;
export type GraphTermUnitAllValue = ValueType<typeof GRAPH_TERM_UNIT>;

export const GRAPH_INTERVAL = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
} as const;

export type GraphInterval = ValueType<typeof GRAPH_INTERVAL>;

export const GRAPH_GROUP_CODE = {
  POWER: 'power',
  ENERGY: 'energy',
  AC_COMBINER: 'ACCombiner',
  GRID: 'grid',
  BATTERY: 'battery',
} as const;

export type GraphGroupCodeValue = ValueType<typeof GRAPH_GROUP_CODE>;

export const GRAPH_SERIES_TYPE = {
  SPLINE: 'spline',
  LINE: 'line',
  COLUMN: 'column',
  PIE: 'pie',
} as const;

export const POWER_STATISTICS: Record<string, PowerKey> = {
  ACCB_PV: 'accbPowerTotal',
  PV: 'totalPvPw',
  BATTERY: 'btPw',
  GRID: 'gridPw',
  LOAD: 'loadPw',
} as const;

export const POWER_STATISTICS_LIST: PowerKey[] = Object.values(POWER_STATISTICS);

export const ENERGY_MINUTE_STATISTICS: Record<string, EnergyMinuteKey> = {
  ACCB_PV_PRODUCED: 'accbPowerAccum',
  PV_PRODUCED: 'totalPvPwhDay',
  BATTERY_CHARGED: 'btChrgPwhDay',
  BATTERY_DISCHARGED: 'btDchrgPwhDay',
  GRID_EXPORTED: 'gridFdinPwhDay',
  GRID_IMPORTED: 'gridPurPwhDay',
  LOAD_CONSUMED: 'loadPwhDay',
} as const;

export const ENERGY_MINUTE_STATISTICS_LIST: EnergyMinuteKey[] = Object.values(ENERGY_MINUTE_STATISTICS);

export const AC_COMBINER_STATISTICS: Record<string, ACCombinerKey> = {
  COMBINER_VOLTAGE: 'accbVoltageSingle',
  COMBINER_CURRENT: 'accbCurrentSingle',
  COMBINER_FREQUENCY: 'accbFrequencySingle',
} as const;

export const AC_COMBINER_STATISTICS_LIST: ACCombinerKey[] = Object.values(AC_COMBINER_STATISTICS);

export const GRID_STATISTICS: Record<string, GridKey> = {
  ACTIVE_POWER: 'meterActivePw',
  REACTIVE_POWER: 'meterReactivePw',
  GRID_VOLTAGE_L1: 'meterVR',
  GRID_VOLTAGE_L2: 'meterVS',
  GRID_CURRENT_L1: 'meterIR',
  GRID_CURRENT_L2: 'meterIS',
  GRID_FREQUENCY: 'meterFreq',
  POWER_FACTOR: 'meterPwfactor',
} as const;

export const GRID_STATISTICS_LIST: GridKey[] = Object.values(GRID_STATISTICS);

export const BATTERY_STATISTICS: Record<string, BatteryKey> = {
  USER_SOC: 'userSocAvg',
  USER_SOC_ESS1: 'userSoc1',
  USER_SOC_ESS2: 'userSoc2',
  USER_SOC_ESS3: 'userSoc3',
  REAL_SOC: 'realSocAvg',
  REAL_SOC_ESS1: 'realSoc1',
  REAL_SOC_ESS2: 'realSoc2',
  REAL_SOC_ESS3: 'realSoc3',
  SOH_ESS1: 'soh1',
  SOH_ESS2: 'soh2',
  SOH_ESS3: 'soh3',
  VOLTAGE_ESS1: 'voltage1',
  VOLTAGE_ESS2: 'voltage2',
  VOLTAGE_ESS3: 'voltage3',
  CURRENT_ESS1: 'current1',
  CURRENT_ESS2: 'current2',
  CURRENT_ESS3: 'current3',
  CELL_VOLTAGE_AVERAGE_ESS1: 'cellVoltageAvg1',
  CELL_VOLTAGE_AVERAGE_ESS2: 'cellVoltageAvg2',
  CELL_VOLTAGE_AVERAGE_ESS3: 'cellVoltageAvg3',
  CELL_VOLTAGE_MAX_ESS1: 'cellVoltageMax1',
  CELL_VOLTAGE_MAX_ESS2: 'cellVoltageMax2',
  CELL_VOLTAGE_MAX_ESS3: 'cellVoltageMax3',
  CELL_VOLTAGE_MIN_ESS1: 'cellVoltageMin1',
  CELL_VOLTAGE_MIN_ESS2: 'cellVoltageMin2',
  CELL_VOLTAGE_MIN_ESS3: 'cellVoltageMin3',
  CELL_TEMPERATURE_AVERAGE_ESS1: 'cellTemperatureAvg1',
  CELL_TEMPERATURE_AVERAGE_ESS2: 'cellTemperatureAvg2',
  CELL_TEMPERATURE_AVERAGE_ESS3: 'cellTemperatureAvg3',
  CELL_TEMPERATURE_MAX_ESS1: 'cellTemperatureMax1',
  CELL_TEMPERATURE_MAX_ESS2: 'cellTemperatureMax2',
  CELL_TEMPERATURE_MAX_ESS3: 'cellTemperatureMax3',
  CELL_TEMPERATURE_MIN_ESS1: 'cellTemperatureMin1',
  CELL_TEMPERATURE_MIN_ESS2: 'cellTemperatureMin2',
  CELL_TEMPERATURE_MIN_ESS3: 'cellTemperatureMin3',
} as const;

export const BATTERY_STATISTICS_LIST: BatteryKey[] = Object.values(BATTERY_STATISTICS);

export const ENERGY_HOUR_STATISTICS: Record<string, EnergyHourKey> = {
  ACCB_PV_PRODUCED: 'accbPowerWh',
  PV_PRODUCED: 'totalPvWh',
  BATTERY_CHARGED: 'btChrgWh',
  BATTERY_DISCHARGED: 'btDchrgWh',
  GRID_EXPORTED: 'gridTrWh',
  GRID_IMPORTED: 'gridObWh',
  LOAD_CONSUMED: 'loadWh',
} as const;

export const ENERGY_HOUR_STATISTICS_LIST: EnergyHourKey[] = Object.values(ENERGY_HOUR_STATISTICS);
