import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Image2 from '@hems/component/src/image/Image2.vue';
import ContentModal from '@hems/component/src/modal/ContentModal.vue';
import { CONTENT_MODAL_SIZE_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'AccountUpdateModal',
    components: {
        ContentModal,
        Image2,
    },
    emits: ['close'],
    setup() {
        const { t } = useI18n();
        const supportEmail = 'na.support@qcells.com';
        const supportCallNumber = '+1) 888-249-7750';
        const lineBreakCode = '%0D%0A';
        const mailLinkHref = computed(() => {
            return `mailto:${supportEmail}?subject=${t('message.update_modal.email_title')}&body=${t('message.update_modal.email_description')}${lineBreakCode}---------${lineBreakCode}${t('account.user_id')}:${lineBreakCode}${t('common.email')}:${lineBreakCode}`;
        });
        return {
            mailLinkHref,
            supportEmail,
            supportCallNumber,
            CONTENT_MODAL_SIZE_TYPE,
        };
    },
});
