import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1405e274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-modal-container" }
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "button-container" }
const _hoisted_5 = { class: "button-text" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    "lock-scroll": false,
    style: {"z-index":"99999"}
  }, {
    default: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(`title-wrapper ${_ctx.isPortalApp && 'title-renewal'}`)
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_Image2, {
            src: 'svg/ic_cancel_light',
            extension: "svg",
            class: "cancel-icon",
            onClick: ($event: any) => (_ctx.onClose(close))
          }, null, 8, ["onClick"])
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", {
            class: _normalizeClass(`content ${_ctx.contentAlign}`)
          }, _toDisplayString(_ctx.content), 3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.type === _ctx.BASIC_MODAL.REMOVE)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "button-wrapper button-remove",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('common.remove')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "button-wrapper button-cancel",
            onClick: ($event: any) => (_ctx.onClose(close))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["button-text", { 'custom-button-text': _ctx.customButtonText }])
            }, _toDisplayString(_ctx.customButtonText || _ctx.closeButtonTextCode), 3)
          ], 8, _hoisted_6),
          (_ctx.type === _ctx.BASIC_MODAL.CONFIRM)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "button-wrapper button-confirm",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
              }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('common.ok')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}