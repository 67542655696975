import isNil from 'lodash-es/isNil';
import { GRAPH_SERIES_TYPE, HOME_2_COLOR, UNIT } from '@hems/util/src/constant';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
const xLabels = ['6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];
const DEFAULT_CHART_HEIGHT = 130;
export const getTodayProductionChartOptions = ({ data, name = '', height = DEFAULT_CHART_HEIGHT, }) => {
    return {
        chart: {
            type: GRAPH_SERIES_TYPE.LINE,
            height,
            zoomType: 'x',
        },
        rangeSelector: {
            enabled: false,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            visible: false,
        },
        xAxis: {
            categories: xLabels,
        },
        tooltip: {
            formatter: (tooltip) => {
                return getTooltipFormatter(tooltip);
            },
        },
        series: [
            {
                type: GRAPH_SERIES_TYPE.LINE,
                name,
                color: HOME_2_COLOR.PRIMARY_MINT,
                marker: {
                    radius: 2,
                },
                data: data.map((item) => item.y),
                lineWidth: 1,
            },
        ],
    };
};
export const getTodayConsumptionChartOptions = ({ data, name = '', height = DEFAULT_CHART_HEIGHT, }) => {
    return {
        chart: {
            type: GRAPH_SERIES_TYPE.LINE,
            height,
            zoomType: 'x',
        },
        rangeSelector: {
            enabled: false,
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            visible: false,
        },
        xAxis: {
            categories: xLabels,
        },
        tooltip: {
            formatter: (tooltip) => {
                return getTooltipFormatter(tooltip);
            },
        },
        series: [
            {
                type: GRAPH_SERIES_TYPE.LINE,
                name,
                color: HOME_2_COLOR.PRIMARY_MINT,
                marker: {
                    radius: 2,
                },
                data: data.map((item) => item.y),
                lineWidth: 1,
            },
        ],
    };
};
export const checkDateRange = (date) => {
    const time = date.substring(8, 10);
    const regexp3hours = /(06|09|12|15|18|21)$/g;
    return regexp3hours.test(time);
};
const getTooltipFormatter = (tooltip) => {
    if (isNil(tooltip.chart.hoverPoint))
        return '';
    const xValue = tooltip.chart.hoverPoint.category;
    const { formattedNumber, unit } = formatUnitNumber(tooltip.chart.hoverPoint.y ?? 0, UNIT.WATT_HOUR);
    const yValue = `<span style="color: ${tooltip.chart.hoverPoint.color}">\u25CF</span> ${formattedNumber} ${unit}`;
    return [xValue, yValue].join('<br />');
};
