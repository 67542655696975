/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useACDeviceType, useSiteDeviceGenType } from '@hems/util/src/composable';
import { AC_DEVICE_TYPE, EMS_TYPE } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
import { isACSystemType, isDeviceACType } from '@hems/util/src/helper/tsguardHelper';
// eslint-disable-next-line import/prefer-default-export
export const useSiteInfo = () => {
    const store = useStore();
    const inventoryInfo = store.state.siteNew.inventoryInfo;
    const siteInfoFOX = store.state.siteNew.siteInfoFOX;
    const siteInfoAC = store.state.siteNew.siteInfoAC;
    const siteInfoAU = store.state.siteNew.siteInfoAU;
    const hubInfo = store.state.siteNew.hubInfo;
    const deviceList = store.state.siteNew.deviceList;
    const getACDeviceProfileList = () => {
        const deviceProfile = deviceList.map((device) => device.deviceInfo);
        /** @TODO map & filter 사용으로 개선 예정 */
        return deviceProfile.reduce((ACTypeGardProfileList, profile) => {
            if (isACSystemType(profile)) {
                ACTypeGardProfileList.push(profile);
            }
            return ACTypeGardProfileList;
        }, []);
    };
    const ACDeviceList = computed(() => deviceList.filter((device) => isDeviceACType(device)));
    /** TS Version 5 에서는 타입 가드가 filter에서도 동작하지만,
     * 프로젝트 TS Version 4 에서는 filter로 타입 가드한 값을 가져다 사용했을 때 TS 에러 발생하여 getACDeviceList 정의 */
    const getACDeviceList = () => {
        const ACDeviceList = [];
        deviceList.map((device) => {
            if (isDeviceACType(device)) {
                ACDeviceList.push(device);
            }
        });
        return ACDeviceList;
    };
    const { isAC } = useSiteDeviceGenType();
    const [masterDeviceInfo] = deviceList.map((device) => device);
    const batteryInfoOfMasterDevice = {
        batteryPackInfoList: masterDeviceInfo.batteryInfo,
    };
    const masterDeviceProfile = isAC
        ? store.state.siteNew.siteInfoAC?.masterACDeviceProfile
        : masterDeviceInfo.deviceInfo;
    /** Site 내의 AC Combiner Profile 조회 */
    const ACCombinerProfile = getACDeviceProfileList().find((profile) => profile.device_type_cd === AC_DEVICE_TYPE.AC_COMBINER);
    /** Configuration을 내릴 메인 Combiner Id 조회 */
    const ACCombinerId = ACCombinerProfile?.device_id || null;
    /** Site 내의 모든 ESS Profile 조회 (1개 이상일 수 있음) */
    const ACESSProfileList = getACDeviceProfileList().filter((device) => device.device_type_cd === AC_DEVICE_TYPE.AC_ESS);
    /** Site 내의 ESS 개수 */
    const ESSCount = ACESSProfileList.length;
    const { isCase6 } = useACDeviceType();
    /** Configuration을 내릴 메인 ESS Id 조회 */
    const getPrimaryESSId = () => {
        /**
         * Solar(Case 1) - AC Combiner(StandAlone)
         * Backup(Case 3) - AC Combiner(StandAlone) / ESS(Primary) / ESS(Secondary)
         * Backup(Case 4) - ESSx2(Primary / Secondary) || ESSx1 (Standalone)
         * Grid(Case 5) - ESSx1 (StandAlone)
         * Grid(Case 6) - AC Combiner(Primary) / ESS(Secondary)
         */
        /** Case 6는 예외적으로 Main ESS 장비가 Secondary 타입 */
        if (isCase6) {
            const MainESSProfile = ACESSProfileList.find((profile) => profile.ems_type_cd === EMS_TYPE.SECONDARY) || ACESSProfileList[0];
            return MainESSProfile?.device_id || null;
        }
        /** Primary type ESS 와 Stand Alone type ESS 는 반드시 하나만 존재 */
        const PrimaryESSProfile = ACESSProfileList.find((profile) => profile.ems_type_cd === EMS_TYPE.PRIMARY || profile.ems_type_cd === EMS_TYPE.STAND_ALONE);
        return PrimaryESSProfile?.device_id || null;
    };
    /** site timezone ID */
    const timezoneId = masterDeviceProfile?.timezone_id ?? getBrowserTimezone();
    const essDeviceList = computed(() => getACDeviceList().filter((device) => device.deviceInfo.device_type_cd === AC_DEVICE_TYPE.AC_ESS));
    return {
        masterDeviceProfile,
        inventoryInfo,
        hubInfo,
        siteInfoFOX,
        siteInfoAC,
        siteInfoAU,
        getACDeviceProfileList,
        ACCombinerProfile,
        batteryInfoOfMasterDevice,
        ACDeviceList,
        getACDeviceList,
        ACCombinerId,
        getPrimaryESSId,
        ESSCount,
        ACESSProfileList,
        timezoneId,
        essDeviceList,
    };
};
