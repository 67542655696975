/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';

import { DEFAULT_LANGUAGE, ENV_LOCALE, INTL_LOCALE_MAP } from '@hems/util/src/constant';

import type { EnvLocale, IntlFormatLocale } from 'hems';

// eslint-disable-next-line import/prefer-default-export
export function useLocale() {
  const locale: EnvLocale = (process.env.VUE_APP_LOCALE as EnvLocale) ?? ENV_LOCALE.EU;
  const isEU = locale === ENV_LOCALE.EU;
  const isAU = locale === ENV_LOCALE.AU;
  const isUS = locale === ENV_LOCALE.US;

  const store = useStore();
  const intlFormatLocale = computed<IntlFormatLocale>(
    () => store.state.appCtx.locale ?? INTL_LOCALE_MAP[DEFAULT_LANGUAGE]
  );

  return {
    locale,
    isEU,
    isAU,
    isUS,
    intlFormatLocale,
  };
}
