import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f3351ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "page-content" };
const _hoisted_2 = { class: "page-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ScrollTop = _resolveComponent("ScrollTop");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_ScrollTop)
    ]));
}
