import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31464757"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "basic-input" }
const _hoisted_2 = ["value", "name", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("input", _mergeProps({
        value: _ctx.inputValue,
        name: _ctx.name,
        type: (_ctx.showPassword ? _ctx.INPUT_TYPE.TEXT : _ctx.type) || _ctx.INPUT_TYPE.TEXT,
        class: {
        [_ctx.className]: true,
        [`${!!_ctx.errorMessage ? _ctx.INPUT_VALID_CLASS.ERROR : _ctx.validClass ? _ctx.validClass : ''}`]: true,
      }
      }, _ctx.$attrs, {
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
      }), null, 16, _hoisted_2),
      (_ctx.type === _ctx.INPUT_TYPE.PASSWORD && !_ctx.$attrs.readonly && !_ctx.$attrs.disabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "password-image",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
          }, [
            (_ctx.showPassword)
              ? (_openBlock(), _createBlock(_component_Image2, {
                  key: 0,
                  src: "icons/ic_hide_default",
                  extension: "svg"
                }))
              : (_openBlock(), _createBlock(_component_Image2, {
                  key: 1,
                  src: "icons/ic_show",
                  extension: "svg"
                }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"]), [
      [_vShow, _ctx.type !== _ctx.INPUT_TYPE.HIDDEN && !_ctx.$attrs.readonly && !_ctx.$attrs.disabled]
    ])
  ], 64))
}