import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ddd3f65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-row" }
const _hoisted_2 = {
  key: 0,
  class: "setting-row-description"
}
const _hoisted_3 = { class: "banner-row" }
const _hoisted_4 = { colspan: "2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", _hoisted_1, [
      _createElementVNode("th", {
        scope: "row",
        class: _normalizeClass(["setting-row-th", { [`indent-${_ctx.indentDepth}`]: _ctx.isIndent }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["setting-row-title", { required: _ctx.required }])
        }, _toDisplayString(_ctx.title), 3),
        _renderSlot(_ctx.$slots, "tooltip", {}, undefined, true),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("td", {
        class: _normalizeClass({ 'multiple-value': _ctx.isMultiValues })
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ]),
    _createElementVNode("tr", _hoisted_3, [
      _createElementVNode("td", _hoisted_4, [
        _renderSlot(_ctx.$slots, "banner", {}, undefined, true)
      ])
    ])
  ], 64))
}