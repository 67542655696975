import type { CellRendererProps } from 'tui-grid/types/renderer';

class ConnectionStatusRenderer {
  private el: HTMLElement;
  private connection: JQuery<HTMLElement>;
  private blindValue: JQuery<HTMLElement>;

  constructor(props: CellRendererProps) {
    this.connection = $('<span class="state_connect"></span>');
    this.blindValue = $('<span class="blind"></span>');
    this.connection.append(this.blindValue);

    this.el = this.connection.get(0);
    this.render(props);
  }

  getElement(): HTMLElement {
    return this.el;
  }

  async render(props: CellRendererProps): Promise<void> {
    const value = props.grid.getValue(props.rowKey, 'is_connect');
    if (value) {
      this.connection.addClass('ty_1');
      this.blindValue.text('connect');
    } else {
      this.connection.addClass('ty_2');
      this.blindValue.text('disconnect');
    }
  }
}

export default ConnectionStatusRenderer;
