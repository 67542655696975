
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PageTitle',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
});
