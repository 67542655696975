import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButtonGroup = _resolveComponent("ToggleButtonGroup")!

  return (_openBlock(), _createBlock(_component_ToggleButtonGroup, {
    "selected-item-values": _ctx.modelValue,
    "toggle-button-items": _ctx.daysOfWeekToggleButtonItems,
    "error-info": _ctx.errorInfo,
    "toggle-button-item-is-disabled-list": _ctx.toggleButtonItemIsDisabledList,
    onChange: _ctx.onSelectItem
  }, null, 8, ["selected-item-values", "toggle-button-items", "error-info", "toggle-button-item-is-disabled-list", "onChange"]))
}