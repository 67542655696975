import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!
  const _component_ButtonNew = _resolveComponent("ButtonNew")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    class: "message-modal",
    "content-class": "message-modal-content",
    "click-to-close": _ctx.clickToClose
  }, {
    default: _withCtx(({ close }) => [
      _createVNode(_component_Image2, {
        class: "image-icon",
        src: `smartmodule/pro/${_ctx.iconName}`,
        extension: "svg"
      }, null, 8, ["src"]),
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.content), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["button-wrapper", _ctx.isDangerAction ? 'flex-row-reverse' : 'flex-row'])
      }, [
        (_ctx.type !== _ctx.MESSAGE_MODAL_TYPE.WARNING)
          ? (_openBlock(), _createBlock(_component_ButtonNew, {
              key: 0,
              type: _ctx.cancelButtonType,
              onClick: ($event: any) => (_ctx.onClose(close))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancelButtonText || _ctx.$t('common.cancel')), 1)
              ]),
              _: 2
            }, 1032, ["type", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonNew, {
          type: _ctx.confirmButtonType,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.confirmButtonText || _ctx.$t('common.confirm')), 1)
          ]),
          _: 1
        }, 8, ["type"])
      ], 2)
    ]),
    _: 1
  }, 8, ["click-to-close"]))
}