
import { defineComponent, reactive } from 'vue';

import { useModelWrapper } from '@hems/util';

import BasicInput from './BasicInput.vue';
export default defineComponent({
  name: 'TruncateInput',
  components: {
    BasicInput,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    tooltipPosition: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      value: useModelWrapper(props, emit),
      readonly: props.readonly,
    });

    return {
      state,
    };
  },
});
